import { CheckOutlined, CloseCircleOutlined, EditFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { CurrencyConversionGetDataDto, CurrencyConverterIdRequestDto, CurrencyConverterService } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Input, InputRef, Popconfirm, Space, Switch, Table, Tooltip } from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { AlertMessages } from '../../../../common';
import { SequenceUtils } from '@exportx/ui-utils';

interface DataType {
  key: string;
  baseCurrency: string;
  quoteCurrency: string;
  exchangeRate: string;
  exchangeDate: string;
}


const CurrencyG = () => {
  const currencyConverterService = new CurrencyConverterService();
  const [gridData, setGridData] = React.useState<CurrencyConversionGetDataDto[]>([]);
  const navigate = useNavigate();

  type DataIndex = keyof DataType;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [searchedText, setSearchedText] = useState("");



  useEffect(() => {
    getCurrencyConversionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const dumpExchangeRates = () => {
    currencyConverterService.dumpExchangeRates('').then(res => {
      if (res.status) {
        getCurrencyConversionData();
        AlertMessages.getSuccessMessage('Received Successfully')
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      };
    }).catch(err => console.log(err.message))
  }


  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });



  const getCurrencyConversionData = () => {
    currencyConverterService.getCurrencyConversionData().then(res => {
      if (res.status) {
        setGridData(res.data);
        console.log(res.data.length,'Count of records')
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
        console.log(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const activateDeactivate = (id) => {
    const req = new CurrencyConverterIdRequestDto(id)
    currencyConverterService.activateDeactivate(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getCurrencyConversionData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }



  const columns: any = [

    {
      title: 'From currency',
      dataIndex: 'baseCurrency',
      width: '80px',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },
      // ...getColumnSearchProps('baseCurrency'),
    },
    {
      title: 'To currency',
      dataIndex: 'quoteCurrency',
      width: '80px',
      // ...getColumnSearchProps('quoteCurrency'),
    },
    {
      title: 'Date',
      dataIndex: 'exchangeDate',
      width: '80px',
      // ...getColumnSearchProps('exchangeDate'),
    },
    {
      title: 'Rate',
      dataIndex: 'exchangeRate',
      width: '80px',
      // ...getColumnSearchProps('exchangeRate'),
      align: 'right' as AlignType
    },


    {
      title: 'Action',
      width: '60px',
      align: 'center' as AlignType,
      render: (record, value) => {
        return <span >

          <Tooltip title={'View'}  > <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }}
            onClick={() => {

              if (record.fromDate || record.toDate) {
                record.fromDate = moment(record.fromDate).format('YYYY-MM-DD')
                record.fromDate = moment(record.Date).format('YYYY-MM-DD')

              }

              navigate("/save-currency-conversion", { state: { record: record, hidden: true } });
            }} /></Tooltip>
          <Divider type="vertical" />
          <Tooltip title={record.isActive ? "Edit" : ""}> <EditFilled style={{ fontSize: '24px', color: record.isActive ? " #1890ff" : "#8080807d" }} onClick={() => {
            navigate("", { state: { record: record } }); if (!record.isActive) {
              AlertMessages.getErrorMessage('You Could Not Update Deactivated Record')
            } else {
              (navigate('/save-currency-conversion', { state: { record: record } }));
            }
          }} /></Tooltip>
          <Divider type="vertical" />
          <Popconfirm onConfirm={() => activateDeactivate(record.currencyConverterId)} title={`${record.isActive ? "Are you sure to Deactivate Record" : "Are You Sure To Activate Record ?"}`}>
            <Tooltip title={record.isActive ? 'Deactivate' : "Activate"}>
              <Switch
                size='default'
                className={record.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseCircleOutlined />}
                checked={record.isActive}
              />
            </Tooltip>
          </Popconfirm >
        </span >
      }


    }
  ]
  return (
    <div>
      <Input.Search
        placeholder="Search"
        allowClear
        onChange={(e) => { setSearchedText(e.target.value) }}
        onSearch={(value) => { setSearchedText(value) }}
        style={{ width: 200, float: "right" }}
      />
      <br></br><br></br>
      <Card title={<span > Currency Convertor Details</span>}
        className='default-card-class'
        extra={<span style={{ color: '#5860f5' }} ><Button onClick={() => navigate("/save-currency-conversion")}>Create</Button> </span>}>

        <Table rowKey={(record) => record.currencyConverterId} columns={columns} pagination={false} dataSource={gridData} />
      </Card>
    </div>
  )
}

export default CurrencyG